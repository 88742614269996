import React from "react";
import { Box, Typography, Card, CardContent, Stack } from "@mui/material";

const Services = ({ isDarkMode }) => {
    const services = [
        { title: "Automatización", description: "Optimiza los procesos de gestión.", icon: "⚙️" },
        { title: "Accesibilidad", description: "Accede desde cualquier dispositivo.", icon: "📱" },
        { title: "Personalización", description: "Soluciones adaptadas a tus necesidades.", icon: "🎨" },
    ];

    return (
        <Box
            component="section"
            sx={{
                py: 8,
                textAlign: "center",
                backgroundColor: isDarkMode ? "grey.900" : "blue.100",
                color: isDarkMode ? "white" : "grey.800",
            }}
        >
            <Typography
                variant="h4"
                component="h2"
                sx={{
                    mb: 4,
                    color: isDarkMode ? "blue.300" : "blue.600",
                }}
            >
                Nuestros Servicios
            </Typography>
            <Stack
                direction="row"
                spacing={4}
                justifyContent="center"
                alignItems="stretch"
                flexWrap="wrap"
                sx={{ maxWidth: 1200, mx: "auto" }}
            >
                {services.map((service, index) => (
                    <Card
                        key={index}
                        sx={{
                            backgroundColor: isDarkMode ? "grey.800" : "white",
                            color: isDarkMode ? "grey.300" : "grey.800",
                            width: 300,
                            transition: "transform 0.3s",
                            "&:hover": { boxShadow: 6, transform: "scale(1.05)" },
                        }}
                    >
                        <CardContent>
                            <Box
                                component="div"
                                sx={{
                                    fontSize: "3rem",
                                    textAlign: "center",
                                    color: isDarkMode ? "blue.300" : "blue.600",
                                    mb: 2,
                                }}
                            >
                                {service.icon}
                            </Box>
                            <Typography
                                variant="h6"
                                component="h3"
                                sx={{
                                    color: isDarkMode ? "blue.300" : "blue.600",
                                    fontWeight: "bold",
                                    mb: 2,
                                }}
                            >
                                {service.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ color: isDarkMode ? "grey.400" : "grey.600" }}
                            >
                                {service.description}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Stack>
        </Box>
    );
};

export default Services;
