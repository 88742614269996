import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { Brightness4, Brightness7, Menu as MenuIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Navbar = ({ toggleTheme, isDarkMode }) => {
    const [anchorEl, setAnchorEl] = useState(null); // Para el menú de hamburguesa
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Detecta pantallas pequeñas

    // Abrir y cerrar el menú de hamburguesa
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="sticky" color="primary">
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                    <IconButton edge="start" color="inherit" aria-label="home">
                        <img className="logo-icon w-24 h-24" src="assets/images/logo/logo.svg" alt="logo" />
                    </IconButton>
                    <Typography
                        variant="h6"
                        color="inherit"
                        sx={{
                            fontWeight: 700,
                            ml: 1,
                        }}
                    >
                        InJaus
                    </Typography>
                </Link>

                {/* Icono de menú para móviles */}
                {isMobile && (
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuClick}>
                        <MenuIcon />
                    </IconButton>
                )}

                {/* Menú de navegación visible en pantallas grandes */}
                {!isMobile && (
                    <Box sx={{ display: 'flex', gap: 2, flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
                        {/* Botones para las dos aplicaciones */}
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="https://asambleas.injaus.co"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                textTransform: 'none',
                                padding: '8px 16px',
                                fontSize: '16px',
                                fontWeight: 600,
                            }}
                        >
                            App Asambleas
                        </Button>
                        <Button
                            variant="contained"
                            color="info"
                            component="a"
                            href="https://app.injaus.co"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                textTransform: 'none',
                                padding: '8px 16px',
                                fontSize: '16px',
                                fontWeight: 600,
                            }}
                        >
                            App InJaus
                        </Button>
                        {/* Botón de Toggle de Tema */}
                        <IconButton
                            color="inherit"
                            onClick={toggleTheme}
                            sx={{
                                sm: 1, // Separación adicional para alineación
                            }}
                        >
                            {isDarkMode ? <Brightness7 /> : <Brightness4 />}
                        </IconButton>
                    </Box>
                )}

                {/* Menu de navegación para móviles */}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleMenuClose}>
                        <a
                            href="https://asambleas.injaus.co"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: 'none',
                                color: theme.palette.text.primary,
                            }}
                        >
                            App Asambleas
                        </a>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <a
                            href="https://app.injaus.co"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: 'none',
                                color: theme.palette.text.primary,
                            }}
                        >
                            App InJaus
                        </a>
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
