import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import {Box, CssBaseline, ThemeProvider} from "@mui/material";
import {darkTheme, lightTheme} from "./configs/theme";
import Policy from "./pages/Policy";
import NotFound from "./pages/NotFound";

function App() {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme ? savedTheme === 'dark' : window.matchMedia('(prefers-color-scheme: dark)').matches;
    });

    // Detectar la preferencia de tema del sistema al cargar la página
    useEffect(() => {
        const systemPreference = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setIsDarkMode(systemPreference); // Configura el estado según la preferencia del sistema

        // Escuchar cambios en la preferencia del sistema
        const handleSystemThemeChange = (e) => {
            setIsDarkMode(e.matches); // Cambia el tema si el usuario cambia la preferencia del sistema
        };

        // Agregar el listener para cambios de tema
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleSystemThemeChange);

        // Limpieza del listener al desmontar el componente
        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleSystemThemeChange);
        };
    }, []);

    // Función para alternar entre los temas
    const toggleTheme = () => {
        const newTheme = !isDarkMode;
        setIsDarkMode(newTheme);
        localStorage.setItem('theme', newTheme ? 'dark' : 'light');
    };

    return (
        <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <CssBaseline /> {/* Aplica los estilos globales */}
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <Router>
                    <Navbar toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
                    <Box sx={{ flexGrow: 1 }}>
                        <Routes>
                            <Route path="/" element={<Home isDarkMode={isDarkMode}/>}/>
                            <Route path="/sobre-nosotros" element={<About isDarkMode={isDarkMode}/>}/>
                            <Route path="/servicios" element={<Services isDarkMode={isDarkMode}/>}/>
                            <Route path="/portafolio" element={<Portfolio isDarkMode={isDarkMode}/>}/>
                            <Route path="/contacto" element={<Contact isDarkMode={isDarkMode}/>}/>
                            <Route path="/policy" element={<Policy isDarkMode={isDarkMode}/>}/>
                            <Route path="*" element={<NotFound isDarkMode={isDarkMode} />} />
                        </Routes>
                    </Box>
                    <Footer/>
                </Router>
            </Box>
        </ThemeProvider>

    );
}

export default App;
