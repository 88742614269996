import React from "react";
import { Box, Button, Typography } from "@mui/material";
import homeImage from '../assets/images/home.png';

const HeroSection = ({ isDarkMode }) => {
    return (
        <Box
            component="section"
            sx={{
                backgroundImage: `url(${homeImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                py: 18,
                textAlign: 'center',
                position: 'relative',
            }}
        >
            {/* Overlay para mejorar el contraste */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'black',
                    opacity: 0.5,
                }}
            />

            {/* Contenido centrado */}
            <Box
                sx={{
                    position: 'relative',
                    zIndex: 10,
                    color: 'white',
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: 'bold',
                        mb: 2,
                        color: isDarkMode ? 'blue.300' : 'blue.600',
                    }}
                >
                    Simplificamos la gestión de propiedades horizontales
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        mb: 4,
                        color: isDarkMode ? 'grey.300' : 'grey.100',
                    }}
                >
                    Innovación, accesibilidad y personalización para tu comunidad.
                </Typography>
                <Button
                    variant="contained"
                    color="info"
                    size="large"
                >
                    Conócenos
                </Button>
            </Box>
        </Box>
    );
};

export default HeroSection;
