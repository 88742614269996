import React from "react";
import { Box, Container, Typography, List, ListItem, ListItemText, Button } from "@mui/material";
import { FaDownload } from "react-icons/fa"; // Para el ícono de descarga

const Policy = ({ isDarkMode }) => {
    const handleDownloadPDF = () => {
        // Enlace al archivo PDF (debe ser accesible desde la carpeta pública o subido a un servidor)
        window.open("/assets/docs/politica-de-privacidad.pdf", "_blank");
    };

    return (
        <Box
            component="section"
            sx={{
                py: 8,
                backgroundColor: isDarkMode ? "grey.900" : "white",
                color: isDarkMode ? "white" : "grey.800",
            }}
        >
            <Container maxWidth="md">
                <Typography
                    variant="h4"
                    component="h1"
                    sx={{
                        mb: 4,
                        color: isDarkMode ? "blue.300" : "blue.600",
                    }}
                >
                    Política de Privacidad
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    En <strong>InJaus Software</strong>, valoramos tu privacidad y estamos comprometidos con la
                    protección de tus datos personales. Esta Política de Privacidad describe cómo recopilamos,
                    usamos y protegemos tu información de acuerdo con la legislación vigente en Colombia.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FaDownload />}
                    onClick={handleDownloadPDF}
                    sx={{
                        mb: 4,
                        backgroundColor: isDarkMode ? "blue.700" : "blue.500",
                        "&:hover": {
                            backgroundColor: isDarkMode ? "blue.600" : "blue.700",
                        },
                    }}
                >
                    Descargar PDF
                </Button>

                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    1. Recopilación de Datos
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Recopilamos información personal solo cuando es necesario para ofrecerte nuestros servicios.
                    Esto incluye:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Tu nombre y datos de contacto (teléfono, correo electrónico)." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Información de tu comunidad o propiedad, si aplica." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Datos proporcionados en formularios de contacto o registro." />
                    </ListItem>
                </List>

                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    2. Uso de los Datos
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Usamos tus datos personales exclusivamente para:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Proveer los servicios contratados." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Responder a consultas o solicitudes de soporte." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Enviar comunicaciones relacionadas con nuestros servicios." />
                    </ListItem>
                </List>

                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    3. Protección de los Datos
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Implementamos medidas de seguridad técnicas y organizativas para proteger tus datos
                    personales contra accesos no autorizados, pérdida o divulgación.
                </Typography>

                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    4. No Compartimos Datos
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Tus datos no serán compartidos con terceros bajo ninguna circunstancia, salvo que sea
                    requerido por la ley colombiana.
                </Typography>

                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    5. Tus Derechos
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Como titular de tus datos personales, tienes derecho a:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Acceder, actualizar o corregir tus datos personales." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Solicitar la eliminación de tus datos cuando lo consideres necesario." />
                    </ListItem>
                </List>

                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    6. Contacto
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Si tienes preguntas sobre esta política o deseas ejercer tus derechos, puedes contactarnos
                    en:
                </Typography>
                <Typography variant="body1">
                    <strong>Correo:</strong> <a href="mailto:admin@injaus.co">admin@injaus.co</a>
                </Typography>
                <Typography variant="body1">
                    <strong>Teléfono:</strong> +57 305 239 7556
                </Typography>

                <Typography variant="body2" sx={{ mt: 4, fontStyle: "italic" }}>
                    Esta política de privacidad fue actualizada por última vez el {new Date().toLocaleDateString()}.
                </Typography>
            </Container>
        </Box>
    );
};

export default Policy;
