import React from "react";
import { Box, Typography, Card, CardContent, CardMedia, Stack } from "@mui/material";

const Portfolio = ({ isDarkMode }) => {
    const projects = [
        { name: "Sistema de Gestión de Propiedades", image: "assets/images/proyecto1.png" },
        { name: "App de Comunicación", image: "assets/images/proyecto2.jpg" },
        { name: "Asambleas virtuales", image: "assets/images/proyecto3.png" },
    ];

    return (
        <Box
            component="section"
            sx={{
                py: 8,
                textAlign: "center",
                backgroundColor: isDarkMode ? "grey.800" : "white",
                color: isDarkMode ? "white" : "grey.800",
            }}
        >
            <Typography
                variant="h4"
                component="h2"
                sx={{
                    mb: 4,
                    color: isDarkMode ? "blue.300" : "blue.600",
                }}
            >
                Nuestro Portafolio
            </Typography>
            <Stack
                direction="row"
                spacing={4}
                justifyContent="center"
                alignItems="stretch"
                flexWrap="wrap"
                sx={{ maxWidth: 1200, mx: "auto" }}
            >
                {projects.map((project, index) => (
                    <Card
                        key={index}
                        sx={{
                            backgroundColor: isDarkMode ? "grey.900" : "grey.100",
                            color: isDarkMode ? "grey.300" : "grey.800",
                            maxWidth: 345,
                            transition: "transform 0.3s",
                            "&:hover": { boxShadow: 6, transform: "scale(1.05)" },
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <CardMedia
                            component="img"
                            height="200"
                            image={project.image}
                            alt={project.name}
                            sx={{
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                                objectFit: "cover",
                            }}
                        />
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography
                                variant="h6"
                                component="h3"
                                sx={{
                                    mt: 2,
                                    color: isDarkMode ? "blue.300" : "blue.600",
                                    fontWeight: "bold",
                                }}
                            >
                                {project.name}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Stack>
        </Box>
    );
};

export default Portfolio;
