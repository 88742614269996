import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import { FaWhatsapp } from "react-icons/fa"; // Icono de WhatsApp

const Contact = ({ isDarkMode }) => {
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });

    // Número de WhatsApp al que se enviará el mensaje
    const whatsappNumber = "+573052397556"; // Cambia esto a tu número con el código internacional

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, message } = formData;

        // Crear el mensaje dinámico
        let whatsappMessage = `Hola, soy ${name}.`;
        if (email) {
            whatsappMessage += ` Mi correo es ${email}.`;
        }
        whatsappMessage += ` ${message}`;

        // Crear el enlace de WhatsApp
        const whatsappURL = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
            whatsappMessage
        )}`;

        // Abrir el enlace en una nueva pestaña
        window.open(whatsappURL, "_blank");
    };

    return (
        <Box
            component="section"
            sx={{
                py: 8,
                textAlign: "center",
                backgroundColor: isDarkMode ? "grey.900" : "blue.100",
                color: isDarkMode ? "white" : "grey.800",
            }}
        >
            <Typography
                variant="h4"
                component="h2"
                sx={{
                    mb: 4,
                    color: isDarkMode ? "blue.300" : "blue.600",
                }}
            >
                Contáctanos por WhatsApp
            </Typography>
            <Box
                component="form"
                sx={{
                    maxWidth: 600,
                    mx: "auto",
                    p: 4,
                    backgroundColor: isDarkMode ? "grey.800" : "white",
                    borderRadius: 2,
                    boxShadow: 3,
                }}
                onSubmit={handleSubmit}
            >
                <TextField
                    fullWidth
                    label="Mi nombre es:"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    margin="normal"
                    required
                    variant="outlined"
                    InputProps={{
                        style: {
                            backgroundColor: isDarkMode ? "#424242" : "#fff",
                            color: isDarkMode ? "white" : "black",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: isDarkMode ? "#BDBDBD" : "black",
                        },
                    }}
                />
                <TextField
                    fullWidth
                    label="Correo Electrónico (opcional)"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        style: {
                            backgroundColor: isDarkMode ? "#424242" : "#fff",
                            color: isDarkMode ? "white" : "black",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: isDarkMode ? "#BDBDBD" : "black",
                        },
                    }}
                />
                <TextField
                    fullWidth
                    label="Mensaje"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    margin="normal"
                    required
                    multiline
                    rows={4}
                    variant="outlined"
                    InputProps={{
                        style: {
                            backgroundColor: isDarkMode ? "#424242" : "#fff",
                            color: isDarkMode ? "white" : "black",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: isDarkMode ? "#BDBDBD" : "black",
                        },
                    }}
                />
                <Button
                    type="submit"
                    fullWidth
                    startIcon={<FaWhatsapp />}
                    sx={{
                        mt: 3,
                        py: 1.5,
                        backgroundColor: "#25D366", // Color verde de WhatsApp
                        color: "white",
                        "&:hover": {
                            backgroundColor: "#128C7E", // Color verde oscuro de WhatsApp al hacer hover
                        },
                    }}
                >
                    Enviar por WhatsApp
                </Button>
            </Box>
        </Box>
    );
};

export default Contact;
