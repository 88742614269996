import React from "react";
import { Box, Typography, Card, CardContent, Stack } from "@mui/material";

const About = ({ isDarkMode }) => {
    return (
        <Box
            component="section"
            sx={{
                py: 8,
                textAlign: "center",
                backgroundColor: isDarkMode ? "grey.800" : "white",
                color: isDarkMode ? "white" : "grey.800",
            }}
        >
            <Typography
                variant="h4"
                component="h2"
                sx={{
                    mb: 4,
                    color: isDarkMode ? "blue.300" : "blue.600",
                }}
            >
                Sobre Nosotros
            </Typography>
            <Typography
                variant="body1"
                component="p"
                sx={{
                    mb: 6,
                    maxWidth: 800,
                    mx: "auto",
                    color: isDarkMode ? "grey.300" : "grey.700",
                }}
            >
                En <strong style={{ color: isDarkMode ? "#64b5f6" : "#1e88e5" }}>InJaus Software</strong>, nuestra misión es
                transformar la administración de propiedades horizontales con herramientas tecnológicas
                modernas y personalizadas. Somos un equipo apasionado por la innovación y la
                excelencia en el desarrollo de software.
            </Typography>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={4}
                justifyContent="center"
                alignItems="center"
            >
                <Card
                    sx={{
                        backgroundColor: isDarkMode ? "grey.900" : "grey.100",
                        color: isDarkMode ? "grey.300" : "grey.800",
                        transition: "transform 0.3s",
                        "&:hover": { boxShadow: 6, transform: "scale(1.05)" },
                        maxWidth: 300,
                        minHeight: 150,
                    }}
                >
                    <CardContent>
                        <Typography
                            variant="h6"
                            component="h3"
                            sx={{ color: isDarkMode ? "blue.300" : "blue.600", mb: 2 }}
                        >
                            Nuestra Visión
                        </Typography>
                        <Typography variant="body2">
                            Ser líderes en soluciones tecnológicas para comunidades.
                        </Typography>
                    </CardContent>
                </Card>
                <Card
                    sx={{
                        backgroundColor: isDarkMode ? "grey.900" : "grey.100",
                        color: isDarkMode ? "grey.300" : "grey.800",
                        transition: "transform 0.3s",
                        "&:hover": { boxShadow: 6, transform: "scale(1.05)" },
                        maxWidth: 300,
                        minHeight: 150,
                    }}
                >
                    <CardContent>
                        <Typography
                            variant="h6"
                            component="h3"
                            sx={{ color: isDarkMode ? "blue.300" : "blue.600", mb: 2 }}
                        >
                            Nuestra Misión
                        </Typography>
                        <Typography variant="body2">
                            Simplificar procesos administrativos y fomentar la transparencia.
                        </Typography>
                    </CardContent>
                </Card>
                <Card
                    sx={{
                        backgroundColor: isDarkMode ? "grey.900" : "grey.100",
                        color: isDarkMode ? "grey.300" : "grey.800",
                        transition: "transform 0.3s",
                        "&:hover": { boxShadow: 6, transform: "scale(1.05)" },
                        maxWidth: 300,
                        minHeight: 150,
                    }}
                >
                    <CardContent>
                        <Typography
                            variant="h6"
                            component="h3"
                            sx={{ color: isDarkMode ? "blue.300" : "blue.600", mb: 2 }}
                        >
                            Nuestros Valores
                        </Typography>
                        <Typography variant="body2">
                            Innovación, confianza y compromiso.
                        </Typography>
                    </CardContent>
                </Card>
            </Stack>
        </Box>
    );
};

export default About;
