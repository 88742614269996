import React from "react";
import Contact from "./Contact";
import About from "./About";
import Portfolio from "./Portfolio";
import Services from "./Services";
import HeroSection from "./HeroSection";

const Home = ({ isDarkMode }) => {
    return (
        <>
            <HeroSection isDarkMode={isDarkMode} />
            <Portfolio isDarkMode={isDarkMode} />
            <Services isDarkMode={isDarkMode} />
            <About isDarkMode={isDarkMode} />
            <Contact isDarkMode={isDarkMode} />
        </>
    );
};

export default Home;
