import React from 'react';
import {Box, Typography, Container, Grid, Link, Button} from '@mui/material';
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa'; // Para los íconos de redes sociales

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: 'primary.main', // Color de fondo del footer
                color: 'white', // Color del texto
                padding: '32px 0',
                marginTop: 'auto', // Asegura que el footer se quede al fondo
                textAlign: 'center',
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4} justifyContent="center">
                    {/* Sección de Contacto */}
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Contacto
                        </Typography>
                        <Typography variant="body2">
                            <strong>Teléfono:</strong> 305 239 7556
                        </Typography>
                        <Typography variant="body2">
                            <strong>Email:</strong>{' '}
                            <Link href="mailto:admin@injaus.co" color="inherit">
                                admin@injaus.co
                            </Link>
                        </Typography>
                        <Typography variant="body2">
                            <Button
                                variant="contained"
                                color="success"
                                startIcon={<FaWhatsapp />}
                                size="large"
                                sx={{
                                    mt: 2,
                                    backgroundColor: "#25D366", // Verde de WhatsApp
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#128C7E", // Verde oscuro de WhatsApp en hover
                                    },
                                }}
                                href="https://api.whatsapp.com/send?phone=+573052397556"
                                target="_blank"
                            >
                                3052397556
                            </Button>
                        </Typography>

                    </Grid>

                    {/* Sección de Redes Sociales */}
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Síguenos
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                            <Link href="https://www.facebook.com/InJausSoftware" target="_blank" color="inherit">
                                <FaFacebook size={24} />
                            </Link>
                            <Link href="https://www.instagram.com/InJausSoftware" target="_blank" color="inherit">
                                <FaInstagram size={24} />
                            </Link>
                            <Link href="https://www.linkedin.com/company/InJausSoftware" target="_blank" color="inherit">
                                <FaLinkedin size={24} />
                            </Link>
                        </Box>
                    </Grid>

                    {/* Sección de Dirección */}
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Dirección
                        </Typography>
                        <Typography variant="body2">
                            Calle 16 # 6 55 - T1 408
                            Cajicá Cundinamarca
                        </Typography>
                    </Grid>

                    {/* Enlace a Política de Privacidad */}
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Enlaces útiles
                        </Typography>
                        <Link href="/policy" color="inherit" variant="body2">
                            Política de privacidad
                        </Link>
                    </Grid>
                </Grid>

                {/* Derechos reservados */}
                <Box sx={{ mt: 4 }}>
                    <Typography variant="body2" color="inherit">
                        &copy; {new Date().getFullYear()} InJaus Software. Todos los derechos reservados.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
