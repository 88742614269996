import { createTheme } from '@mui/material/styles';

const darkPaletteText = {
    primary: 'rgb(255,255,255)',
    secondary: 'rgb(148, 163, 184)',
    disabled: 'rgb(156, 163, 175)',
};

const lightPaletteText = {
    primary: 'rgb(17, 24, 39)',
    secondary: 'rgb(107, 114, 128)',
    disabled: 'rgb(149, 156, 169)',
};

// Tema Oscuro (dark8)
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        divider: 'rgba(241,245,249,.12)',
        text: {
            primary: darkPaletteText.primary,
        },
        primary: {
            light: '#BEBFC8',
            main: '#1e293b',
            dark: '#0D0F21',
            contrastText: darkPaletteText.primary,
        },
        secondary: {
            light: '#CBD7FE',
            main: '#5079FC',
            dark: '#2749FA',
            contrastText: darkPaletteText.primary,
        },
        background: {
            paper: '#1e293b',
            default: '#1e293b',
        },
        error: {
            light: '#ffcdd2',
            main: '#f44336',
            dark: '#b71c1c',
        },
    },
    status: {
        danger: 'orange',
    },
});

// Tema Claro (light)
const lightTheme = createTheme({
    palette: {
        mode: 'light',
        divider: '#e2e8f0',
        text: {
            primary: lightPaletteText.primary,
        },
        primary: {
            light: '#6293d9',
            main: '#1e293b',
            dark: '#0D0F21',
            contrastText: darkPaletteText.primary,
        },
        secondary: {
            light: '#818cf8',
            main: '#4f46e5',
            dark: '#3730a3',
            contrastText: darkPaletteText.primary,
        },
        background: {
            paper: '#FFFFFF',
            default: '#f1f5f9',
        },
        error: {
            light: '#ffcdd2',
            main: '#f44336',
            dark: '#b71c1c',
        },
    },
});

export { darkTheme, lightTheme };
