import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const NotFound = ({ isDarkMode }) => {
    // Variantes de animación para Framer Motion
    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { type: "spring", stiffness: 100, damping: 10 } },
    };

    const fadeInVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { delay: 0.5, duration: 0.5 } },
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                textAlign: "center",
                backgroundColor: isDarkMode ? "grey.900" : "grey.100",
                color: isDarkMode ? "white" : "grey.800",
            }}
        >
            {/* Animación para el código 404 */}
            <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: "6rem",
                        fontWeight: "bold",
                        color: isDarkMode ? "blue.300" : "blue.600",
                    }}
                >
                    404
                </Typography>
            </motion.div>

            {/* Animación para el subtítulo */}
            <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
            >
                <Typography
                    variant="h5"
                    sx={{
                        mb: 4,
                        color: isDarkMode ? "grey.300" : "grey.700",
                    }}
                >
                    ¡Ups! La página que buscas no existe.
                </Typography>
            </motion.div>

            {/* Animación para el botón */}
            <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
            >
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/"
                    sx={{
                        backgroundColor: isDarkMode ? "blue.700" : "blue.500",
                        "&:hover": {
                            backgroundColor: isDarkMode ? "blue.600" : "blue.700",
                        },
                    }}
                >
                    Volver al Inicio
                </Button>
            </motion.div>
        </Box>
    );
};

export default NotFound;
